<template>
    <div id="myScreen" class="swiper-container">
        <div class="swiper-wrapper">
            <div class="swiper-slide">
                <img class="mapImg1" src="../../assets/screen/map.png">
                <img class="mapImg2" src="../../assets/screen/lbx.png">
                <img class="mapImg3" src="../../assets/screen/jt.png">
                <div class="header-container">
                    <div class="header-title">{{companyName || ''}}销售指挥部</div>
                    <div class="header-date">{{date}}</div>
                    <div class="header-f11">按F11切换全屏模式</div>
                </div>
                <div class="main-container">
                    <div class="main-left">
                        <div class="main-item height300">
                            <div class="lineLB"></div>
                            <div class="lineRB"></div>
                            <div class="item-title">销售订单数据</div>
                            <div class="rowItem">
                                <div class="rowItem-item">
                                    <div class="item-label">当前订单数</div>
                                    <span class="item-text">{{screenData.nowOrderNum || '0'}}</span>
                                </div>
                                <div class="rowItem-item">
                                    <div class="item-label">今日新增订单</div>
                                    <span class="item-text">{{screenData.todayAddOrderNum || '0'}}</span>
                                </div>
                                <div class="rowItem-item">
                                    <div class="item-label">本月新增订单</div>
                                    <span class="item-text">{{screenData.monthAddOrderNum || '0'}}</span>
                                </div>
                            </div>
                            <div class="item-line"></div>
                            <div class="rowItem">
                                <div class="rowItem-item">
                                    <div class="item-label">今日流失订单数</div>
                                    <span class="item-text">{{screenData.todayFailOrderNum || '0'}}</span>
                                </div>
                                <div class="rowItem-item">
                                    <div class="item-label">本月流失订单数</div>
                                    <span class="item-text">{{screenData.monthFailOrderNum || '0'}}</span>
                                </div>
                                <div class="rowItem-item">
                                    <div class="item-label">续签预警订单数</div>
                                    <span class="item-text">{{screenData.warningOrderNum || '0'}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="main-item height300">
                            <div class="lineLB"></div>
                            <div class="lineRB"></div>
                            <div class="item-title">店铺类目分布</div>
                            <div v-for="(item, index) in categoryData" :key="index" :class="`category0${index + 1} category-item${item.platformName.length > 2 ? '100' : '80'}`" class="category-item80">
                                <span>{{item.platformName}}</span>
                                <span>{{item.shopNum || '0'}}</span>
                            </div>
                            <!-- <div class="category-item100 category02">
                                <span>母婴</span>
                                <span>121</span>
                            </div>
                            <div class="category-item100 category03">
                                <span>零食</span>
                                <span>121</span>
                            </div>
                            <div class="category-item100 category04">
                                <span>百货</span>
                                <span>121</span>
                            </div>
                            <div class="category-item100 category05">
                                <span>电器</span>
                                <span>121</span>
                            </div>
                            <div class="category-item100 category06">
                                <span>女装</span>
                                <span>121</span>
                            </div> -->
                        </div>
                        <div class="main-item height268">
                            <div class="lineLB"></div>
                            <div class="lineRB"></div>
                            <div class="item-title">订单续签记录</div>
                            <div v-if="renewalVis" class="swiper-renewal" style="height: 188px;overflow:hidden;padding:0 20px 20px 20px;margin-top: 20px;box-sizing:border-box">
                                <div class="swiper-wrapper">
                                    <div v-for="(item, index) in renewalData" :key="index" class="swiper-slide">
                                        <div class="renewal-title">
                                            <span>{{item.create_name || '--'}}({{item.roleName}})</span>
                                            <span>{{item.create_time || '--'}}</span>
                                        </div>
                                        <div class="renewal-info">订单-ID： {{item.order_no || '--'}}</div>
                                        <div class="renewal-info">“{{item.shop_names || '--'}}” 签约{{RangeDateNum(item.start_time, item.end_time)}}天服务</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="main-center">
                        <div class="center-item">
                            <div class="lineLB"></div>
                            <div class="lineRB"></div>
                            <div class="lineRT"></div>
                            <div class="lineLT"></div>
                            <div class="centerItem">
                                <div class="centerItem-label">年度目标金额（元）</div>
                                <div class="centerItem-text" style="color: #00A2F6">{{orderTarget || 0}}</div>
                            </div>
                            <div class="centerItem">
                                <div class="centerItem-label">客户公海数（位）</div>
                                <div class="centerItem-text" style="color: #9CFF00">{{screenData.openCustomerNum || '0'}}</div>
                            </div>
                            <div class="centerItem">
                                <div class="centerItem-label">累计订单金额（元）</div>
                                <div class="centerItem-text" style="color: #00EAFF">{{screenData.cumulativePrice || '0'}}</div>
                            </div>
                            <div class="centerItem">
                                <div class="centerItem-label">今日新增客户数（位）</div>
                                <div class="centerItem-text" style="color: #FCE700">{{screenData.todayAddCustomerNum || '0'}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="main-right">
                        <div class="main-item height300">
                            <div class="lineLB"></div>
                            <div class="lineRB"></div>
                            <div class="item-title">新签订单播报</div>
                            <div v-if="renewalNewVis" class="swiper-renewalNew" style="height: 220px;overflow:hidden;padding:0 20px 20px 20px;margin-top: 20px;box-sizing:border-box">
                                <div class="swiper-wrapper">
                                    <div v-for="(item, index) in renewalNewData" :key="index" class="swiper-slide">
                                        <div class="renewal-title">
                                            <span>{{item.create_name || '--'}}({{item.roleName}})</span>
                                            <span>{{item.create_time || '--'}}</span>
                                        </div>
                                        <div class="renewal-info">订单-ID： {{item.order_no || '--'}}</div>
                                        <div class="renewal-info">“{{item.shop_names || '--'}}” 签约{{RangeDateNum(item.start_time, item.end_time)}}天服务</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="main-item height590">
                            <div class="lineLB"></div>
                            <div class="lineRB"></div>
                            <div class="item-title">实时工作记录</div>
                            <div v-if="workRecordVis" class="swiper-workRecord" style="height: 510px;overflow:hidden;padding:0 20px 20px 20px;margin-top: 20px;box-sizing:border-box">
                                <div class="swiper-wrapper">
                                    <div v-for="(item, index) in workRecordData" :key="index" class="swiper-slide">
                                        <div class="renewal-title">
                                            <span>{{item.operationName || '--'}}</span>
                                            <span>{{item.time || '--'}}</span>
                                        </div>
                                        <div class="renewal-info">{{item.value || '--'}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-slide">
                <div class="header-container">
                    <div class="header-title">{{companyName || ''}}客服服务中心</div>
                    <div class="header-date">{{date}}</div>
                    <div class="header-f11">按F11切换全屏模式</div>
                </div>
                <div class="main-container">
                    <div class="main-left width400" >
                        <div class="main-item height368">
                            <div class="lineLB"></div>
                            <div class="lineRB"></div>
                            <div class="item-title">今日上班客服</div>
                            <div class="slide2-label">今日上班客服人数</div>
                            <div class="slide2-service">{{screenData.todayWorkServiceNum || '0'}}人</div>
                        </div>
                        <div class="main-item height522">
                            <div class="lineLB"></div>
                            <div class="lineRB"></div>
                            <div class="item-title">客服服务数据</div>
                            <br/>
                            <div class="rowItem">
                                <div class="rowItem-item" style="width: 45%">
                                    <div class="item-label">当前首响时间</div>
                                    <span class="item-text">{{screenData.todayFirstTime || '0'}} 秒</span>
                                </div>
                                <div class="rowItem-item" style="width: 45%">
                                    <div class="item-label">当前平响时间</div>
                                    <span class="item-text">{{screenData.todayAvgTime || '0'}} 秒</span>
                                </div>
                            </div>
                            <br/>
                            <div class="item-line"></div>
                            <br/>
                            <div class="rowItem">
                                <div class="rowItem-item" style="width: 45%">
                                    <div class="item-label">当前问答比</div>
                                    <span class="item-text">{{screenData.todayQaRate || '0'}} %</span>
                                </div>
                                <div class="rowItem-item" style="width: 45%">
                                    <div class="item-label">昨日问答比</div>
                                    <span class="item-text">{{screenData.yesterdayQaRate || '0'}} %</span>
                                </div>
                            </div>
                            <br/>
                            <div class="item-line"></div>
                            <br/>
                            <div class="rowItem">
                                <div class="rowItem-item" style="width: 45%">
                                    <div class="item-label">昨日首响时间</div>
                                    <span class="item-text">{{screenData.yesterdayFirstTime || '0'}} 秒</span>
                                </div>
                                <div class="rowItem-item" style="width: 45%">
                                    <div class="item-label">昨日平响时间</div>
                                    <span class="item-text">{{screenData.yesterdayAvgTime || '0'}} 秒</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="main-center width1019">
                        <div class="main-item height186">
                            <div class="lineLB"></div>
                            <div class="lineRB"></div>
                            <div class="item-title">业务总览</div>
                            <div class="business-info">
                                <div class="business-item">
                                    <span>服务中店铺数</span>
                                    <span style="color:#00AAFF">{{screenData.serviceShopNum || '0'}} 家</span>
                                </div>
                                <div class="business-item">
                                    <span>服务中子账号数</span>
                                    <span style="color:#00EAFF">{{screenData.serviceSubaccountNum || '0'}} 个</span>
                                </div>
                                <div class="business-item">
                                    <span>平均店铺咨询量</span>
                                    <span style="color:#9CFF00">{{screenData.shopConsultationNum || '0'}} 条</span>
                                </div>
                                <div class="business-item">
                                    <span>平均客服带店数</span>
                                    <span style="color:#FCE700">{{screenData.beltStoreNum || '0'}} 家</span>
                                </div>
                            </div>
                        </div>
                        <div class="main-item height162">
                            <div class="lineLB"></div>
                            <div class="lineRB"></div>
                            <div class="item-title">店铺质检</div>
                            <div class="ShopQuality">
                                <div class="ShopQuality-item">
                                    <span>实时质检数</span>
                                    <span style="color:#00EAFF">{{screenData.todayQualityNum || '0'}} 条</span>
                                </div>
                                <div class="ShopQuality-item">
                                    <span>昨日质检数</span>
                                    <span style="color:#00AAFF">{{screenData.yesterdayQualityNum || '0'}} 条</span>
                                </div>
                            </div>
                        </div>
                        <div class="main-item height522">
                            <div class="lineLB"></div>
                            <div class="lineRB"></div>
                            <div class="item-title">各平台店铺数据</div>
                             <div class="shopInfo">
                                <table>
                                    <tr class="trWhite"><th>各大平台</th><th>店铺数</th><th>子账号数</th></tr>
                                    <tr :class="{'trWhite': index%2}" v-for="(item, index) in shopCategory" :key="index"><td>{{item.platformName || '--'}}</td><td>{{item.shopNum || '--'}}</td><td>{{item.subNum || '--'}}</td></tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="main-right width400">
                        <div class="main-item" style="height: 100%;display:flex;flex-direction: column;">
                            <div class="lineLB"></div>
                            <div class="lineRB"></div>
                            <div class="item-title">预警消息中心</div>
                            <div v-if="messageVis" class="swiper-message" style="height: 830px;overflow:hidden;padding:0 20px 20px 20px;margin-top: 20px;box-sizing:border-box">
                                <div class="swiper-wrapper">
                                    <div v-for="(item, index) in messageData" :key="index" class="swiper-slide">
                                        <div class="renewal-title">
                                            <span>{{item.create_time || '--'}}</span>
                                            <span></span>
                                        </div>
                                        <div class="renewal-info">{{item.advice_title || '--'}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="swiper-pagination"></div>
    </div>
</template>
<script>
import Swiper from 'swiper'
import "swiper/dist/css/swiper.min.css";
import { Config, RangeDateNum, TimeTransform } from "../../utils/index.js"
import { getDataScreen, screenPlatform, messageInfo, renewalRecord, renewalRecordNew, workRecord, getCategory, getOrderTarget } from "../../service/screen.js"
export default {
    components: { },
    data () {
        return {
            RangeDateNum,
            companyName: this.$sto.get(Config.constants.comName),
            companyId: this.$sto.get(Config.constants.comId),
            screenData: {},
            shopCategory: {},
            messageData: [],
            messageVis: false,
            renewalData: [],
            renewalVis: false,
            renewalNewData: [],
            renewalNewVis: false,
            workRecordData: [],
            workRecordVis: false,
            categoryData: [],
            date: '',
            timer: '',
            timer5: '',
            orderTarget: ''
        }
    },
    destroyed() {
        if (this.timer) {
            clearInterval(this.timer)
        }
        if (this.timer5) {
            clearInterval(this.timer5)
        }
    },
    created () {
         
    },
    mounted () {
        this.onSwiperFun()
        this.getOrderTarget()
        this.currentDate()
        this.getDataScreen()
        this.screenPlatform()
        this.getCategory()

        this.messageInfo()
        this.renewalRecord()
        this.renewalRecordNew()
        this.workRecord()
        this.timer5 = setInterval(() => {
            this.messageInfo()
            this.renewalRecord()
            this.renewalRecordNew()
            this.workRecord()
        }, 300000)

        // let myScreen = document.getElementById('myScreen')
        // if(myScreen.requestFullScreen) { 
        //     myScreen.requestFullScreen(); 
        // } else if(myScreen.webkitRequestFullScreen ) { 
        //     myScreen.webkitRequestFullScreen(); 
        // } else if(myScreen.mozRequestFullScreen) { 
        //     myScreen.mozRequestFullScreen(); 
        // } 

        // if (myScreen.requestFullScreen) {     
        //     myScreen.requestFullScreen.call(myScreen);   //执行这个请求的方法
        // } else if (typeof window.ActiveXObject !== "undefined") {  
        //  //window.ActiveXObject判断是否支持ActiveX控件    
        //     //这里其实就是模拟了按下键盘的F11，使浏览器全屏
        //     var wscript = new ActiveXObject("WScript.Shell"); //创建ActiveX  
        //     if (wscript !== null) {    //创建成功
        //         wscript.SendKeys("{F11}");//触发f11   
        //     }   
        // }  
    },
    methods: {
        async getOrderTarget () { // 目标金额
            let { data } = await getOrderTarget({companyId: this.companyId})
            this.orderTarget = data
        },
        async getCategory () { // 类目分布
            let { data } = await getCategory({companyId: this.companyId})
            this.categoryData = data
        },
        currentDate () {
            let newDate = (new Date()).getTime()
            this.timer = setInterval(() => {
                newDate += 1000
                this.date = TimeTransform(newDate)
            }, 1000)
        },
        async workRecord () { // 工作记录
            this.workRecordVis = false
            let { data } = await workRecord({companyId: this.companyId, pageNum: 1, pageSize: 10})
            this.workRecordData = data.records || []
            this.workRecordVis = true
            setTimeout(() => {
                let swiper4 = new Swiper(".swiper-workRecord", {
                    direction : 'vertical',
                    slidesPerView :'auto',
                    spaceBetween: 30,
                    loop: false,
                    autoplay : 4000,
                    autoplayDisableOnInteraction : false,
                    autoHeight:true,
                    autoplayStopOnLast : true,
                })
                swiper4.init();
            }, 500); 
        },
        async renewalRecordNew () { // 新签订单
            this.renewalNewVis = false
            let { data } = await renewalRecordNew({companyId: this.companyId})
            this.renewalNewData = data || []
            this.renewalNewVis = true
            setTimeout(() => {
                let swiper3 = new Swiper(".swiper-renewalNew", {
                    direction : 'vertical',
                    slidesPerView :'auto',
                    spaceBetween: 30,
                    loop: false,
                    autoplay : 4000,
                    autoplayDisableOnInteraction : false,
                    autoHeight:true,
                    autoplayStopOnLast : true,
                })
                swiper3.init();
            }, 500); 
        },
        async renewalRecord () { // 订单续签记录
            this.renewalVis = false
            let { data } = await renewalRecord({companyId: this.companyId})
            this.renewalData = data || []
            this.renewalVis = true
            setTimeout(() => {
                let swiper2 = new Swiper(".swiper-renewal", {
                    direction : 'vertical',
                    slidesPerView :'auto',
                    spaceBetween: 30,
                    loop: false,
                    autoplay : 4000,
                    autoplayDisableOnInteraction : false,
                    autoHeight:true,
                    autoplayStopOnLast : true,
                })
                swiper2.init();
            }, 500); 
        },
        async messageInfo () { // 预警消息
            this.messageVis = false
            let { data } = await messageInfo({companyId: this.companyId})
            this.messageData = data.records || []
            this.messageVis = true
            setTimeout(() => {
                let swiper1 = new Swiper(".swiper-message", {
                    direction : 'vertical',
                    slidesPerView :'auto',
                    spaceBetween: 30,
                    loop: false,
                    loopedSlides: 0,
                    autoplay : 4000,
                    autoplayDisableOnInteraction : false,
                    autoHeight:true,
                    autoplayStopOnLast : true,
                })
                swiper1.init();
            }, 500); 
            
        },
        async screenPlatform () { // 店铺类目
            let { data } = await screenPlatform({companyId: this.companyId})
            this.shopCategory = data
        },
        async getDataScreen () { // 数据大屏
            let { data } = await getDataScreen({companyId: this.companyId})
            this.screenData = data || {}
        },
        onSwiperFun () {
            // var mySwiper = 
            var mySwiper = new Swiper('.swiper-container', {
                // autoplay: false,//可选选项，自动滑动
                direction : 'horizontal',
                pagination : '.swiper-pagination',
                // loop: true,
                paginationClickable :true,
                autoplay : 10000,
                // autoplayDisableOnInteraction : false,
            })
            console.log(mySwiper)
        },
        
    }
}
</script>
<style lang="less" scoped>
.swiper-pagination {
    bottom: 35px;
}
.swiper-pagination /deep/.swiper-pagination-bullet {
    width: 50px;
    height: 5px;
    border-radius: 0%;
    opacity: 0.5;
    background: #fff;
}
.swiper-pagination /deep/.swiper-pagination-bullet-active {
    background: #fff;
    opacity: 1;
}
.renewal-title {
    display: flex;
    justify-content: space-between;
    span {
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 6px;
        color: #fff;
    }
}
.renewal-info {
    font-size: 18px;
    line-height: 20px;
    // margin-bottom: 12px;
    color: #fff;
    white-space: pre-line;
}
.main-container {
    display: flex;
    justify-content: space-between;
    padding: 28px 30px 40px;
    box-sizing: border-box;
    flex: 1;
    font-family: SourceHanSansCN-Regular;
    .lineLB {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 10px;
        height: 10px;
        border-left: 2px solid #02A6B5;
        border-bottom: 2px solid #02A6B5;
    }
    .lineRB {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 10px;
        height: 10px;
        border-right: 2px solid #02A6B5;
        border-bottom: 2px solid #02A6B5;
    }
    .lineLT {
        position: absolute;
        top: 0;
        left: 0;
        width: 10px;
        height: 10px;
        border-left: 2px solid #02A6B5;
        border-top: 2px solid #02A6B5;
    }
    .lineRT {
        position: absolute;
        top: 0;
        right: 0;
        width: 10px;
        height: 10px;
        border-top: 2px solid #02A6B5;
        border-right: 2px solid #02A6B5;
    }
    .width400 {
        width: 400px !important;
    }
    .width1019 {
        width: 1019px !important;
    }
    .height162 {
        height: 162px;
    }
    .height186 {
        height: 186px;
    }
    .height368 {
        height: 368px;
    }
    .height522 {
        height: 522px;
    }
    .height590 {
        height: 590px;
    }
    .height300 {
        height: 300px;
    }
    .height268 {
        height: 268px;
    }
    .main-item {
        position: relative;
        background: rgba(255, 255, 255, 0.05);
        .slide2-label {
            text-align: center;
            color: #fff;
            font-size: 24px;
            line-height: 24px;
            margin: 37px 0 30px;
        }
        .slide2-service {
            width: 360px;
            height: 120px;
            border: 1px solid #00EAFF;
            margin: 0 auto;
            color: #00EAFF;
            font-size: 64px;
            line-height: 120px;
            text-align: center;
        }
        .category-item80 {
            position: absolute;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.1);
            display: flex;
            flex-direction: column;
            padding: 19px 0 14px;
            box-sizing: border-box;
            justify-content: space-between;
            align-items: center;
            span {
                color: #fff;
                font-size: 18px;
                line-height: 18px;
            }
        }
        .category-item100 {
            position: absolute;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.1);
            display: flex;
            flex-direction: column;
            padding: 29px 0 24px;
            box-sizing: border-box;
            justify-content: space-between;
            align-items: center;
            span {
                color: #fff;
                font-size: 18px;
                line-height: 18px;
            }
        }
        .category01 {
            top: 84px;
            left: 76px;
        }
        .category02 {
            top: 80px;
            left: 255px;
        }
        .category03 {
            top: 100px;
            left: 390px;
        }
        .category04 {
            top: 180px;
            left: 30px;
        }
        .category05 {
            top: 160px;
            left: 175px;
        }
        .category06 {
            top: 190px;
            left: 310px;
        }
        .item-line {
            height: 4px;
            width: 90%;
            margin: auto;
            background: url('../../assets/screen/外发光2.png') no-repeat 0 0;
            background-size: 100% 100%;
        }
        .rowItem {
            display: flex;
            justify-content: space-between;
            padding: 0 30px;
            box-sizing: border-box;
            margin-top: 20px;
            .rowItem-item {
                width: 32%;
            }
            .item-label {
                font-size: 18px;
                color: #fff;
                opacity: 0.5;
                line-height: 18px;
            }
            .item-text {
                font-size: 32px;
                color: #C5CCFF;
                line-height: 72px;
            }
        }
        .item-title {
            height: 60px;
            line-height: 60px;
            font-size: 24px;
            color: #fff;
            text-indent: 17px;
            background: url('../../assets/screen/矩形 平台.png') no-repeat 0 0;
        }
    }
    .main-left, .main-right {
        width: 510px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .main-center {
        width: 700px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .shopInfo {
            padding: 30px;
            box-sizing: border-box;
            width: 100%;
            table {
                width: 100%;
                tr {
                    height: 50px;
                }
                .trWhite {
                    background: rgba(255, 255, 255, 0.1);
                }
                td, th {
                    color: #fff;
                    font-size: 18px;
                    text-align: center;
                    line-height: 50px;
                }
                th {
                    font-size: 24px;
                    font-weight: 500;
                }
            }
        }
        .ShopQuality {
            display: flex;
            justify-content: space-between;
            padding: 0 150px 0 30px;
            box-sizing: border-box;
            padding-top: 26px;
            .ShopQuality-item {
                display: flex;
                align-items: center;
                span {
                    &:nth-of-type(1) {
                        color: #fff;
                        font-size: 24px;
                        margin-right: 60px;
                    }
                    &:nth-of-type(2) {
                        color: #fff;
                        font-size: 48px;
                        line-height: 48px;
                    }
                }
            }
        }
        .business-info {
            display: flex;
            padding: 0 40px;
            box-sizing: border-box;
            justify-content: space-between;
            width: 100%;
            .business-item {
                display: flex;
                flex-direction: column;
                span {
                    &:nth-of-type(1) {
                        color: #fff;
                        opacity: 0.5;
                        font-size: 18px;
                        line-height: 18px;
                        margin: 20px 0;
                    }
                    &:nth-of-type(2) {
                        color: #fff;
                        font-size: 48px;
                        line-height: 48px;
                    }
                }
            }
        }
        .center-item {
            width: 100%;
            height: 300px;
            background: rgba(255, 255, 255, 0.05);
            position: relative;
            .centerItem {
                display: inline-block;
                width: 49%;
                .centerItem-label {
                    color: #fff;
                    font-size: 24px;
                    line-height: 24px;
                    margin: 38px 0 20px;
                    text-indent: 60px;
                }
                .centerItem-text {
                    color: #fff;
                    font-size: 48px;
                    line-height: 48px;
                    text-indent: 60px;
                }
            }
        }
    }
}
.header-container {
    height: 100px;
    width: 100%;
    background: url('../../assets/screen/head_bg.png') no-repeat 0 0;
    background-size: 100% cover;
    position: relative;
    .header-f11 {
        position: absolute;
        top: 0;
        left: 48px;
        height: 70px;
        line-height: 70px;
        font-size: 16px;
        color: #C5CCFF;
    }
    .header-date {
        position: absolute;
        top: 0;
        right: 48px;
        height: 70px;
        line-height: 70px;
        font-size: 24px;
        color: #C5CCFF;
    }
    .header-title {
        font-size: 42px;
        color: #fff;
        font-weight: 600;
        text-align: center;
        height: 72px;
        line-height: 72px;
    }
}
.swiper-container {
    width: 100vw;
    height: 100vh;
    background: url('../../assets/screen/bg.jpg') no-repeat 0 72px;
    background-size: 100% cover;
    background-color: #00072d;
    text-align: left;
    .swiper-wrapper {
        width: 100%;
        height: 100%;
        .swiper-slide {
            display: flex;
            flex-direction: column;
            position: relative;
            .mapImg1 {
                width: 490px;
                height: 490px;
                position: absolute;
                left: 0;
                right: 0;
                top: 472px;
                margin: auto;
                opacity: 0.4;
            }
            .mapImg2 {
                width: 640px;
                height: 640px;
                position: absolute;
                left: 0;
                right: 0;
                top: 390px;
                margin: auto;
                opacity: 0.4;
                animation: mapImg2 15s linear 0s infinite;
                @keyframes mapImg2 {
                    from {
                        transform: rotate(0deg);
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }
            }
            .mapImg3 {
                width: 540px;
                height: 540px;
                position: absolute;
                left: 0;
                right: 0;
                top: 448px;
                margin: auto;
                opacity: 0.4;
                animation: mapImg3 10s linear 0s infinite;
                @keyframes mapImg3 {
                    from {
                        transform: rotate(360deg);
                    }
                    to {
                        transform: rotate(0deg);
                    }
                }
            }
        }
    }
}  
</style>
