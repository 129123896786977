import fetch from "./xhr/index";

/**
 * 数据大屏-真实数据
 * @parama
 */
 export const getDataScreen = param => fetch("GET", "/dataRecord/serveData/getDataScreen", param);

/**
 * 数据大屏-店铺类目
 * @paramas
 */
 export const screenPlatform = param => fetch("GET", "/dataRecord/serveData/getDataScreenPlatform", param);

/**
 * 数据大屏-预警消息
 * @paramas
 */
 export const messageInfo = param => fetch("POST", "/sys/sysInfo/screenEarlyWarning", param);

/**
 * 数据大屏-订单续签记录
 * @paramas
 */
 export const renewalRecord = param => fetch("GET", "/dataRecord/serveData/getDataScreenRenewalOrderList", param);

/**
 * 数据大屏-新签记录
 * @paramas
 */
 export const renewalRecordNew = param => fetch("GET", "/dataRecord/serveData/getDataScreenNewOrderList", param);

/**
 * 数据大屏-工作记录
 * @paramas
 */
 export const workRecord = param => fetch("POST", "/cms/cmsWorkLog/screenList", param);

/**
 * 数据大屏-类目分类
 * @paramas
 */
 export const getCategory = param => fetch("GET", "/dataRecord/serveData/getCategory", param);

/**
 * 数据大屏-目标金额
 * @paramas
 */
 export const getOrderTarget = param => fetch("GET", "/dataRecord/serveData/getOrderTarget", param);